<template>
  <div uk-height-viewport class="uk-flex uk-flex-middle">
    <div class="uk-width-3-3@m uk-width-1-3@s m-auto rounded">
      <div class="uk-child-width-2-2@m uk-grid-collapse" uk-grid>
        <div class="uk-card-default p-5 rounded">
          <div class="mb-4">
            <h3 class="mb-3"> {{ $t('register.header') }}</h3>
            <div class="uk-alert-danger" uk-alert v-if="errors">
              <a class="uk-alert-close" uk-close></a>
              <p style="color:red;"><i class="uil-exclamation-triangle"></i> {{ errors }}</p>
            </div>

            <div class="uk-alert-success" uk-alert v-if="message">
              <a class="uk-alert-close" uk-close></a>
              <p style="color:green;"><i class="uil-exclamation-triangle"></i> {{ message }}
              </p>
            </div>
          </div>
          <form>
            <div class="uk-form-group">
              <label class="uk-form-label"> {{ $t('register.name') }}</label>
              <div class="uk-position-relative w-100">
                <span class="uk-form-icon">
                  <i class="icon-feather-user"></i>
                </span>
                <input class="uk-input" type="text" required v-model="form.name">
              </div>
            </div>

            <div class="uk-form-group">
              <label class="uk-form-label"> {{ $t('register.surname') }}</label>
              <div class="uk-position-relative w-100">
                <span class="uk-form-icon">
                  <i class="icon-feather-user"></i>
                </span>
                <input class="uk-input" type="text" required v-model="form.surname">
              </div>
            </div>

            <div class="uk-form-group">
              <label class="uk-form-label"> {{ $t('register.phone') }}</label>
              <div class="uk-position-relative w-100">
                <vue-tel-input v-model="form.phone" :inputOptions="options"></vue-tel-input>
              </div>
            </div>

            <div class="uk-form-group" v-if="!form.company_id">
              <label class="uk-form-label"> {{ $t('register.email') }}</label>
              <div class="uk-position-relative w-100">
                <span class="uk-form-icon">
                  <i class="icon-feather-mail"></i>
                </span>
                <input class="uk-input" type="email" required v-model="form.email">
              </div>
            </div>

            <div class="uk-form-group">
              <label class="uk-form-label">{{ $t('register.password') }}</label>
              <div class="uk-position-relative w-100">
                <span class="uk-form-icon">
                  <i class="icon-feather-lock"></i>
                </span>
                <input class="uk-input" type="password" placeholder="Şifrenizi giriniz" required v-model="form.password"
                  :type="passwordType ? 'text' : 'password'">
                <i class="icon-feather-eye open-password" @click="showPassword('passwordType')"></i>
              </div>
            </div>

            <div class="uk-form-group">
              <label class="uk-form-label">{{ $t('register.password_confirmation') }}</label>
              <div class="uk-position-relative w-100">
                <span class="uk-form-icon">
                  <i class="icon-feather-lock"></i>
                </span>
                <input class="uk-input" type="password" required placeholder="Şifrenizi tekrar giriniz"
                  v-model="form.password_confirmation" :type="confirmPasswordType ? 'text' : 'password'">
                <i class="icon-feather-eye open-password" @click="showPassword('confirmPasswordType')"></i>
              </div>
              <div class="bg-white W-100" uk-alert>
                <p style="color:grey;" v-if="passwordValidation.hasUpperLetter">
                  <i class="fa fa-check d-inline-flex" aria-hidden="true"></i>
                  {{ $t('errors.password_must_have_upper_latter') }}

                </p>
                <p style="color:grey;" v-if="passwordValidation.hasLowerLetter">
                  <i class="fa fa-check d-inline-flex" aria-hidden="true"></i>
                  {{ $t('errors.password_must_have_lower_latter') }}

                </p>

                <p style="color:grey;" v-if="passwordValidation.hasNumber">
                  <i class="fa fa-check d-inline-flex" aria-hidden="true"></i>
                  {{ $t('errors.password_must_have_number') }}

                </p>
                <p style="color:grey;" v-if="passwordValidation.hasAtLeast8Letters">
                  <i class="fa fa-check d-inline-flex" aria-hidden="true"></i>
                  {{ $t('errors.password_must_more_than_7') }}

                </p>

                <p style="color:red;" v-if="!passwordValidation.hasUpperLetter">
                  {{ $t('errors.password_must_have_upper_latter') }}
                </p>
                <p style="color:red;" v-if="!passwordValidation.hasLowerLetter">
                  {{ $t('errors.password_must_have_lower_latter') }}
                </p>

                <p style="color:red;" v-if="!passwordValidation.hasNumber">
                  {{ $t('errors.password_must_have_number') }}
                </p>
                <p style="color:red;" v-if="!passwordValidation.hasAtLeast8Letters">
                  {{ $t('errors.password_must_more_than_7') }}
                </p>

              </div>
            </div>

            <div class="mt-4 uk-flex-middle uk-grid-small" uk-grid>
              <div class="uk-width-auto@s">
                <button v-if="!this.form.company_id" type="button" class="btn btn-warning mr-2"
                  @click="$router.push({ name: 'login' })">
                  {{ $t('general.cancel') }}
                </button>
                <button type="button" @click.prevent="onSubmit()" :disabled="!submitButtonDisabled"
                  class="btn btn-default">
                  {{ $t('general.save') }}
                </button>
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
  </div>

</template>

<script>

import { SET_ERROR, REGISTER_WITH_SIGN, REGISTER, ERROR } from "@/core/services/store/auth.module";
import { TheMask } from 'vue-the-mask'
import store from "@/core/services";
import { DomainMapper } from "@/core/config/domain-mapper";
export default {
  name: "ForgotPassword",
  components: {
    TheMask
  },
  data() {
    return {
      form: {
        name: '',
        surname: '',
        password: '',
        phone: '',
        password_confirmation: '',
        signature: null,
        company_id: null,
        expires: null,
        email: null,
        alias: process.env.VUE_APP_DEFAULT_SUBDOMAIN,
        country_phone_code: ''
      },
      options: {
        showDialCode: true,
        placeholder: '5XXXXXXXXX',
        styleClasses: ['uk-input', 'border-0', 'w-100', 'h-0', 'form-input', 'uk-form-input', 'p-0', 'm-0']
      },
      message: null,
      errorMessages: [],
      passwordType: false,
      confirmPasswordType: false,
    }
  },
  computed: {
    errors() {
      return store.getters[ERROR];
    },
    passwordValidation() {
      let validation = {
        'hasLowerLetter': false,
        'hasUpperLetter': false,
        'hasNumber': false,
        'hasAtLeast8Letters': false,
      };
      if (this.form.password) {
        let lowerCaseLetters = /[a-z]/g;
        let upperCaseLetters = /[A-Z]/g;
        let numbers = /[0-9]/g;

        if (this.form.password.match(lowerCaseLetters)) {
          validation.hasLowerLetter = true;
        }
        if (this.form.password.match(upperCaseLetters)) {
          validation.hasUpperLetter = true;
        }
        if (this.form.password.match(numbers)) {
          validation.hasNumber = true;

        }
        if (this.form.password.length >= 8) {
          validation.hasAtLeast8Letters = true;
        }
      }
      return validation
    },
    submitButtonDisabled() {
      this.errorMessages = [];

      if (this.form.password != null) {

        let lowerCaseLetters = /[a-z]/g;
        let upperCaseLetters = /[A-Z]/g;
        let numbers = /[0-9]/g;
        if (!this.form.password.match(lowerCaseLetters)) {
          this.errorMessages.push(
            this.$t('errors.password_must_have_lower_latter'),
          );
        }
        if (!this.form.password.match(upperCaseLetters)) {
          this.errorMessages.push(
            this.$t('errors.password_must_have_upper_latter'),
          );
        }
        if (!this.form.password.match(numbers)) {
          this.errorMessages.push(
            this.$t('errors.password_must_have_number'),
          );
        } if (this.form.password.length < 7) {
          this.errorMessages.push(
            this.$t('errors.password_must_more_than_7'),
          );
        }
        return this.errorMessages.length === 0;
      }
    },

  },
  methods: {
    showPassword(element) {
      switch (element) {
        case "passwordType":
          this.passwordType = !this.passwordType;
          break;
        case "confirmPasswordType":
          this.confirmPasswordType = !this.confirmPasswordType;
          break;
      }
    },
    onSubmit() {
      let email = this.email
      this.message = null
      this.form.country_phone_code = this.form.phone.substring(0, this.form.phone.indexOf(' ')).substring(1);
      this.form.phone = this.form.phone.substring(this.form.phone.indexOf(' ') + 1).replaceAll(' ', '');
      if (['production', 'staging'].includes(process.env.NODE_ENV)) {
        this.form.alias = window.location.hostname.split(".")[0];
        if (["production"].includes(process.env.NODE_ENV) && DomainMapper.hasOwnProperty(window.location.hostname)){
          this.form.alias = DomainMapper[window.location.hostname] || this.form.alias;
        }
      }
      this.submitButtonDisabled = true;
      if (this.form.company_id) {
        this.form.url = 'api/user/store/' + this.form.company_id + '?' + this.$route.params.params
        // send forgot password request
        this.$store.dispatch(REGISTER_WITH_SIGN, this.form)
          .then((response) => {
            this.message = this.$t('register.register_successful')
            setTimeout(() => this.$router.push({ name: 'login' }), 1000);
          })
          .catch(() => {
            this.submitButtonDisabled = false;
          })
      } else {
        // send register request

        let formData = _.clone(this.form);

        formData.alias = window.location.hostname.split(".")[0];
        if (["production"].includes(process.env.NODE_ENV) && DomainMapper.hasOwnProperty(window.location.hostname)){
          formData.alias = DomainMapper[window.location.hostname] || formData.alias;
        }

        formData.phone = formData.phone.replace(" ", "");
        this.$store.dispatch(REGISTER, this.form)
          .then((response) => {
            this.message = this.$t('register.register_successful')
            setTimeout(() => this.$router.push({ name: 'login' }), 1000);
          })
          .catch(() => {
            this.submitButtonDisabled = false;
          })
      }
    }
  },
  mounted() {


    this.$store.commit(SET_ERROR, '')
    let uri = this.$route.params.params;

    if (uri) {
      let vars = uri.split('&');
      let getVars = {};
      let tmp = '';
      vars.forEach(function (v) {
        tmp = v.split('=');
        if (tmp.length == 2)
          getVars[tmp[0]] = tmp[1];
      });
      this.form.company_id = getVars.id;
      this.form.expires = getVars.expires;
      this.form.signature = getVars.signature;
    }
  }
}
</script>

<style scoped>
.open-password {
  position: absolute;
  top: 50%;
  right: 15px;
  transform: translateY(-50%);
}

.open-password:hover {
  cursor: pointer;
}
</style>
